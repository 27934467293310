import { Route, Routes, useLocation } from "react-router-dom";
import React, { lazy, Suspense } from 'react';

import "./style/App.css";

import logoCubeText from "./style/images/LogoCube_Text.webp";

import { AuthProvider } from "./context/authContext";

import { LandingPage } from "./pages/LandingPage";

import { LandingLayout } from "./layouts/LandingLayout";

import PrivateRoute from "./components/PrivateRoute";

const Login = lazy(() => import('./pages/LogIn'));
const SignUp = lazy(() => import('./pages/Signup'));
const UpdateProfile = lazy(() => import('./pages/UpdateProfile'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));

const Revisions = lazy(() => import('./pages/revisions'));
const Cartes = lazy(() => import('./pages/cartes'));
const Groupes = lazy(() => import('./pages/groupes'));
const Calendrier = lazy(() => import('./pages/calendrier'));
const CreerCarte = lazy(() => import('./pages/CreerCarte'));
const ModifierCarte = lazy(() => import('./pages/ModifierCarte'));
const Dashboard = lazy(() => import('./pages/dashboard'));

const CartesLayout = lazy(() => import("./layouts/CartesLayout"));
const DashboardLayout = lazy(() => import("./layouts/DashboardLayout"));
const RevisionsLayout = lazy(() => import("./layouts/RevisionsLayout"));
const GroupesLayout = lazy(() => import("./layouts/GroupesLayout"));
const CalendrierLayout = lazy(() => import("./layouts/CalendrierLayout"));
const UpdateProfileLayout = lazy(() => import("./layouts/UpdateProfileLayout"));
const SignInLayout = lazy(() => import("./layouts/SignInLayout"));
const SignUpLayout = lazy(() => import("./layouts/SignUpLayout"));
const ForgotPasswordLayout = lazy(() => import("./layouts/ForgotPasswordLayout"));
const CreerModifierCarteLayout = lazy(() => import("./layouts/CreerModifierCarteLayout"));

const HelmetProvider = lazy(() => import('react-helmet-async').then(module => ({ default: module.HelmetProvider })));
const Helmet = lazy(() => import('react-helmet-async').then(module => ({ default: module.Helmet })));


function App() {

  const location = useLocation();
  const isLandingPage = location.pathname === "/";

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>CUBE - Une meilleure manière d'apprendre - Préinscriptions ouvertes</title>
          <meta name="description" content="CUBE est une solution d’apprentissage innovante qui facilite la mémorisation à long terme grâce à des méthodes éprouvées. Cette solution répond à la question : 'comment apprendre ?'. Les inscriptions pour rejoindre la version bêta sont ouvertes." />
          <meta name="keywords" content="apprentissage, mémorisation, éducation, auto-test, boîte de Leitner, révision, groupe d'étude, comment apprendre, quelle est la meilleure manière d'apprendre, quel est le meilleur moyen d'apprendre" />
          <link rel="canonical" href="https://cube-app.online" />
          <link rel="sitemap" type="application/xml" title="Sitemap" href="https://www.cube-app.online/sitemap.xml" />
          <meta property="og:title" content="Découvrir CUBE" />
          <meta property="og:description" content="CUBE est une solution d’apprentissage innovante qui facilite la mémorisation à long terme grâce à des méthodes éprouvées." />
          <meta property="og:url" content="https://cube-app.online" />
          <meta property="og:type" content="article" />
        </Helmet>
      </HelmetProvider>
      <Suspense fallback={<div>Chargement...</div>}>
      <div className={`cubeWrapper ${isLandingPage ? 'blur' : ''}`}>
        <div className="logoTextWrapper">
          <img className="logoCube" src={logoCubeText} alt="CUBE logo"></img>
        </div>
        {/* <Link className="connexionLanding" to="/connexion" style={{ display: isLandingPage ? 'block' : 'none' }}>Connexion</Link> */}
        {isLandingPage && <a className="connexionLanding" href='#pre_inscription'>Me préinscrire</a>}
      </div>
      <Routes>
        <Route path="/" element={<LandingLayout/>}>
          <Route path="" element={<Suspense fallback={<div>Chargement...</div>}><LandingPage/></Suspense>}></Route>
        </Route>
      </Routes>
      <AuthProvider>
          <Routes>
            <Route path="/connexion" element={<PrivateRoute><SignInLayout/></PrivateRoute>}>
              <Route path="" element={<Login/>}></Route>
            </Route>
            <Route path="/inscription" element={<PrivateRoute><SignUpLayout/></PrivateRoute>}>
              <Route path="" element={<SignUp/>}></Route>
            </Route>
            <Route path="/editionProfil" element={<PrivateRoute><UpdateProfileLayout/></PrivateRoute>}>
              <Route path="" element={<UpdateProfile/>}></Route>
            </Route>
            <Route path="/motDePasseOublie" element={<PrivateRoute><ForgotPasswordLayout/></PrivateRoute>}>
              <Route path="" element={<ForgotPassword/>}></Route>
            </Route>
            <Route exact path="/bienvenue" element={<PrivateRoute><DashboardLayout /></PrivateRoute>}>
              <Route path="" element={<Dashboard />}></Route>
            </Route>
            <Route path="/cartes" element={<PrivateRoute><CartesLayout /></PrivateRoute>}>
              <Route path="" element={<Cartes />}></Route>
            </Route>
            <Route path="/nouvelleCarte" element={<PrivateRoute><CreerModifierCarteLayout/></PrivateRoute>}>
              <Route path="" element={<CreerCarte />}></Route>
            </Route>
            <Route path="/modifierCarte" element={<PrivateRoute><CreerModifierCarteLayout/></PrivateRoute>}>
              <Route path="" element={<ModifierCarte />}></Route>
            </Route>
            <Route path="/revisions" element={<PrivateRoute><RevisionsLayout /></PrivateRoute>}>
              <Route path="" element={<Revisions />}></Route>
            </Route>
            <Route path="/groupes" element={<PrivateRoute><GroupesLayout/></PrivateRoute>}>
              <Route path="" element={<Groupes />}></Route>
            </Route>
            <Route path="/calendrier" element={<PrivateRoute><CalendrierLayout/></PrivateRoute>}>
              <Route path="" element={<Calendrier />}></Route>
            </Route>
          </Routes>
      </AuthProvider>
      </Suspense>
    </>
  ); 
}

export default App;
