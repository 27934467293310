import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../firebase'

import axios from 'axios'

const AuthContext = React.createContext()

async function handleSignup(email, firstName, lastName, userID) {
    await axios.post('https://cubedev.pythonanywhere.com/wroot', {
        uid: userID,
        email: email,
        nom: lastName,
        prenom: firstName,
      });
}

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {

    const[currentUser, setCurrentUser] = useState();
    const[loading, setLoading] = useState(true);

    function signup(email, password, firstName, lastName) {
        return auth.createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                let userID = userCredential.user.uid
                return handleSignup(email, firstName, lastName, userID);
            })
            .catch((error) => {
                console.error("Erreur lors de l'inscription :", error);
                throw error;
            });
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        localStorage.clear();
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })
        return unsubscribe
    }, [])

    const value = {
        currentUser,
        login,
        logout,
        signup,
        resetPassword
    }
  return (
    <AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>
  )
}
