import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import axios from "axios";
import emailjs from '@emailjs/browser';

import "../style/LandingPage.css";

import logoCubeText from "../style/images/LogoCube_Text.webp";

const Spline = lazy(() => import('@splinetool/react-spline'));
const HelmetProvider = lazy(() => import('react-helmet-async').then(module => ({ default: module.HelmetProvider })));
const Helmet = lazy(() => import('react-helmet-async').then(module => ({ default: module.Helmet })));

const FonctionnementSection = lazy(() => import('../components/FonctionnementSection'));
const OrganisationSection = lazy(() => import('../components/OrganisationSection'));
const ApprentissageSection = lazy(() => import('../components/ApprentissageSection'));
const GroupesSection = lazy(() => import('../components/GroupesSection'));
const CentreSection = lazy(() => import('../components/CentreSection'));
const StatistiquesSection = lazy(() => import('../components/StatistiquesSection'));

export function LandingPage() {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);
  const lastScrolledSection = useRef(null);
  const scrollTimeout = useRef(null);
  const [prenom, setPrenom] = useState('');
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const emailjsUserId = process.env.REACT_APP_EMAIL_USER_ID;
  
    try {
      const postResponse = await axios.post('https://cube-app.online/API/preinscription', {
        email: email,
        prenom: prenom,
        nom: nom
      });
  
      if (postResponse.status === 200) {
        try {
          await emailjs.send(emailjsServiceId, emailjsTemplateId, {
            prenom,
            nom,
            email,
          }, emailjsUserId);
  
        } catch (emailError) {
          alert('Une erreur est survenue');
        }
  
        e.target.reset();
        alert('Inscription réussie, Vous faites désormais partie des pioniers !');
        setPrenom('');
        setNom('');
        setEmail('');
  
      } else {
        alert('Vérifiez la conformité des données');
      }
    } catch (error) {
      alert('Une erreur est survenue lors de l\'inscription');
    }
  };

  useEffect(() => {
    const simulateLoad = () => {
      setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }, 1000); 
    };

    const handleDomContentLoaded = () => {
      simulateLoad();
      setContentLoaded(true);
    };

    if (document.readyState === 'complete') {
      handleDomContentLoaded();
    } else {
      window.addEventListener('load', handleDomContentLoaded);
      return () => window.removeEventListener('load', handleDomContentLoaded);
    }
  }, []);

  useEffect(() => {
    if (loading || !contentLoaded) return;

    const handleScroll = () => {
      const sections = ['.presentation', '.fonctionnement', '.organisation', '.apprentissage', '.groupes', '.centre', '.statistiques', '.pre_inscription'];
      const darkModeSections = ['.apprentissage', '.groupes'];
      let mostVisibleSection = null;
      let maxVisibility = 0;
      let isInDarkModeSection = false;

      sections.forEach(selector => {
        const section = document.querySelector(selector);
        if (section) {
          const rect = section.getBoundingClientRect();
          const visibleHeight = Math.max(0, Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0));
          if (visibleHeight > maxVisibility) {
            maxVisibility = visibleHeight;
            mostVisibleSection = section;
          }
        }
      });

      darkModeSections.forEach(selector => {
        const section = document.querySelector(selector);
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top <= (window.innerHeight * 0.85 || document.documentElement.clientHeight * 0.75) && rect.bottom >= (window.innerHeight * 0.40 || document.documentElement.clientHeight * 0.40)) {
            isInDarkModeSection = true;
          }
        }
      });

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animate');
            }
        });
    });
    
    document.querySelectorAll('.animation, .highlight').forEach(element => {
        observer.observe(element);
    });

      const logoCube = document.querySelector('.logoCube');
      const cubeText = document.querySelector('.cubeText');
      const connexionLanding = document.querySelector('.connexionLanding');

      if (isInDarkModeSection) {
        document.body.classList.add('dark-mode');
        if (logoCube) logoCube.classList.add('invert');
        if (cubeText) cubeText.classList.add('light-text');
        if (connexionLanding) connexionLanding.classList.add('light-text');
      } else {
        document.body.classList.remove('dark-mode');
        if (logoCube) logoCube.classList.remove('invert');
        if (cubeText) cubeText.classList.remove('light-text');
        if (connexionLanding) connexionLanding.classList.remove('light-text');
      }

      const isMobile = window.innerWidth <= 780;

      if (mostVisibleSection) {
        if (mostVisibleSection !== lastScrolledSection.current) {
          lastScrolledSection.current = mostVisibleSection;

          if (scrollTimeout.current) {
            clearTimeout(scrollTimeout.current);
          }

          if (!isMobile) {
            if (mostVisibleSection.classList.contains('presentation')) {
              scrollTimeout.current = setTimeout(() => {
                window.scrollTo({
                  top: mostVisibleSection.offsetTop,
                  behavior: 'smooth'
                });
              }, 100);
            } else {
              scrollTimeout.current = setTimeout(() => {
                mostVisibleSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }, 50);
            }
          }
        } else {
          const rect = mostVisibleSection.getBoundingClientRect();
          if (rect.top !== 0) {
            if (scrollTimeout.current) {
              clearTimeout(scrollTimeout.current);
            }
            if (!isMobile) {
              scrollTimeout.current = setTimeout(() => {
                mostVisibleSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }, 50);
            }
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, [loading, contentLoaded]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 780);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>CUBE - Une meilleure manière d'apprendre - Préinscriptions ouvertes</title>
          <meta name="description" content="CUBE est une solution d’apprentissage innovante qui facilite la mémorisation à long terme grâce à des méthodes éprouvées. Cette solution répond à la question : 'comment apprendre ?'. Les inscriptions pour rejoindre la version bêta sont ouvertes." />
          <meta name="keywords" content="apprentissage, mémorisation, éducation, auto-test, boîte de Leitner, révision, groupe d'étude, comment apprendre, quelle est la meilleure manière d'apprendre, quel est le meilleur moyen d'apprendre" />
          <link rel="canonical" href="https://cube-app.online" />
          <link rel="sitemap" type="application/xml" title="Sitemap" href="https://www.cube-app.online/sitemap.xml" />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Découvrir CUBE" />
          <meta property="og:description" content="CUBE est une solution d’apprentissage innovante qui facilite la mémorisation à long terme grâce à des méthodes éprouvées." />
          <meta property="og:url" content="https://cube-app.online" />
          <meta property="og:type" content="article" />
        </Helmet>
      </HelmetProvider>
      <Suspense fallback={<div>Chargement ...</div>}>
      {loading && (
        <div className={`loading-container ${fadeOut ? 'fade-out' : ''}`}>
          <img className="logoCube-loading" loading='lazy' src={logoCubeText} alt="Logo CUBE" />
        </div>
      )}
        {isMobile ? (
        <section className='presentation'>
          <div className='cube3D'>
            <Spline scene="https://prod.spline.design/dzeqZnYSNIPDrpDO/scene.splinecode" />
          </div>
          <h1 className='presentation-text'>
            Une solution d’<span className='highlightTitle'>apprentissage</span> efficace
            pour une mémorisation à <span className='highlightTitle'>long terme</span>.
          </h1>
          <h1 className='presentation-slogan'>
            Il est temps d’apprendre comment apprendre.
          </h1>
        </section>
          ) : (
          <section className='presentation'>
            <div className='cube3D'>
              <Spline scene="https://prod.spline.design/FlhVzqM5WTlrX9N5/scene.splinecode" />
            </div>
            <h1 className='presentation-text'>
              Une solution d’<span className='highlightTitle'>apprentissage</span> efficace<br/>
              pour une mémorisation à <span className='highlightTitle'>long terme</span>.
            </h1>
            <h1 className='presentation-slogan'>
              Il est temps d’apprendre comment apprendre.
            </h1>
          </section>
          )}  
      <FonctionnementSection isMobile={isMobile} />
      <OrganisationSection isMobile={isMobile} />
      <ApprentissageSection isMobile={isMobile} />
      <GroupesSection isMobile={isMobile} />
      <CentreSection isMobile={isMobile} />
      <StatistiquesSection isMobile={isMobile} />
      <section className='pre_inscription' id='pre_inscription'>
        <div className='pre_inscription-wrapper'>
          <h3 className='pre-titre titre-viewport'>Restez informés des nouvelles avancées</h3>
          <h3 className='pre-sous_titre'>Profitez de contenu exclusif et d’un accès anticipé pour les membres pré-inscrits !</h3>
          <form className='form_pre' onSubmit={handleSubmit} name='formulaire_preinscription'>
            <div className='formNomPrenom'>
              <input
                type='text'
                placeholder='Prenom'
                className='prenom'
                name='First name'
                autoComplete="on"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
                required
              />
              <input
                type='text'
                placeholder='Nom'
                className='nom'
                name='Last Name'
                autoComplete="on"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                required
              />
            </div>
            <input
              type='email'
              placeholder='Email'
              className='email'
              name='Email address'
              autoComplete="on"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type='submit' className='bouton'>Rejoindre la beta</button>
          </form>
        </div>
        <footer className='droits'>CUBE 2024 © All Rights Reserved</footer>
      </section>
      </Suspense>
    </>
  )
}